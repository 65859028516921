/*******************************
    User Variable Overrides
*******************************/

ul.ui.list li:before,
.ui.bulleted.list .list > .item:before,
.ui.bulleted.list > .item:before {
  content: '|';
  color: #fff;
  margin-left: -1rem;
}

ul.ui.horizontal.bulleted.list li::before, .ui.horizontal.bulleted.list > .item::before {
    color: #fff;
}