/*******************************
         Site Overrides
*******************************/
body {
    background: #EBEBEB;
}

.megamenu {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #fff;
    position: fixed;      
    display: none;
    z-index: 9999;
    width: 100vw;
    color: #000;
    left: 0px;

    .ui.container {
        max-width: 1024px !important;
    }
  
    .mega-dropdown:hover > & {
        display: block;
    }
}