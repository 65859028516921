/*******************************
         Site Overrides
*******************************/

.ui.modal > .actions {
    border-top: none;
    background: none;
}

.ui.modal > .header {
    border-bottom: none;
}

.ui.button.hoverDarkenYellowBg,
.hoverDarkenYellowBg,
.hoverYellowBg,
.ui.button.hoverYellowBg {
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.ui.modal .actions > .button {
    position: relative;
    z-index: 0;
    overflow: hidden;
    transition: all .3s ease-in-out;
    color: #fff;
    background-color: #F28E1C;
}

.ui.modal .actions > .button:after {
    position: absolute;
    content: '';
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #bd6f17;
    z-index: -1;
    transition: all .3s ease-in-out;
}

.ui.modal .actions > .button:hover:after {
    left: 0;
}