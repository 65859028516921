/*******************************
         Site Overrides
*******************************/


.ui.radio.checkbox .box:before, .ui.radio.checkbox label:before {
    width: 23px;
    height: 23px;
}

.ui.checkbox input[type="checkbox"], .ui.checkbox input[type="radio"] {
    width: 23px;
    height: 23px;
}

.ui.radio.checkbox {
    line-height: 23px;
}

.ui.radio.checkbox .box:after, .ui.radio.checkbox label:after {
    top: -3px;
    left: -4px;
    width: 31px;
    height: 31px;
}

.ui.checked.radio.checkbox .box:after, .ui.checked.radio.checkbox label:after,
.ui.radio.checkbox input:checked ~ .box:after, .ui.radio.checkbox input:checked ~ label:after {
    background-color: #F28E1C;
    opacity: 1;
}

.ui.checked.radio.checkbox .box:before, .ui.checked.radio.checkbox label:before,
.ui.checkbox input:checked ~ .box:before, .ui.checkbox input:checked ~ label:before {
    border-color: #F28E1C;
}